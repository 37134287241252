export enum Permissions {
  TaxDocumentUpload = 'upload:tax_document',
  ReadDocumentDispatch = 'read:follow-dispatch',
  ReadAdvanceBordero = 'read:advance-bordero',
  WriteAdvanceBordero = 'write:advance-bordero',
  ReadSettlementNotes = 'read:settlement-notes',
  WriteSettlementNotes = 'write:settlement-notes',
  AdminVendorsUpload = 'admin:upload:vendor',
  AdminReadVendor = 'admin:read:vendor',
  AdminWriteVendor = 'admin:write:vendor',
  AdminReadTaxes = 'admin:read:interest',
  AdminWriteTaxes = 'admin:write:interest',
  AdminReadBordero = 'admin:read:bordero'
}
